import Stack from '@mui/material/Stack';

import NavList from './nav-list';
import { navConfig } from '@/app/utilities/consts';

// ----------------------------------------------------------------------

export default function NavDesktop() {
  return (
    <Stack component="nav" direction="row" spacing={5} sx={{ mr: 2.5, height: 1 }}>
      {navConfig.map(item => (
        <NavList key={item.title} data={item} />
      ))}
    </Stack>
  );
}
